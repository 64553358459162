.music-container
    height: 92vh
    width: 100vw

    .title
        padding-top: 10vh
        font-size: 8vh

    .music-carousel-container
        width: 90%
        margin: 10vh auto 0 auto
        height: 50vh

    .sc-container
        height: 100%
        display: flex
        justify-content: center
    
    .audioFrame
        height: 100%
        width: 80%

    .splide, .splide__track
        height: 100%

@media screen and (max-height: 800px) and (min-aspect-ratio: 7/4) 
    .music-container
        .title
            padding-top: 0 


@media screen and (orientation:portrait)
    .music-container
        .title
            font-size: 5vh

    .music-carousel-container
            width: 100%
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    // .music-container
//     width: 80%
//     height: 55vh
//     transform: translateY(9vh)
//     margin-left: auto
//     margin-right: auto

// .title
//     display: block
//     font-family: 'Poppins', sans-serif
//     width: 100%
//     text-align: center
//     font-size: 5vw
//     color: white

// .splide, ,.splide__track
//     height: 100%
    
// .music-carousel-container
//     margin-top: 5vh     
//     height: 100%

// .sc-container
//     height: 55vh
//     margin-left: auto
//     margin-right: auto
//     transform: translateY(9vh)
//     width: 80%



// .audioFrame
//     width: 30vw
//     height: 30vw

// @media screen and (orientation:portrait)
//     .music-container
//         width: 90%
//         height: 70vh
//         transform: translateY(5vh)
        
//     .music-container .title
//         display: block
//         font-family: 'Poppins', sans-serif
//         width: 90%
//         text-align: center
//         font-size: 5vh
//         color: white
//         margin-left: auto
//         margin-right: auto
//         transform: translateY(5vh)
//         font-weight: 600

//     .music-carousel-container
//         margin-top: 15vh
//         height: 40vh

//     .audioFrame
//         width: 60vw
//         height: 40vw

//     .sc-container
//         display: flex
//         justify-content: center

//     // .splide__slide
//     //     display: flex
//     //     flex-direction: column
//     //     justify-content: center