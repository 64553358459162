.projects-container
    height: 92vh
    width: 100vw

    .title
        padding-top: 15vh 
        font-size: 8vh
    
    .carousel-container
        width: 90%
        margin: auto auto auto auto
        height: 50vh
    
    .video-container
        height: 100%
        display: flex
        flex-direction: column
        align-content: center
        justify-content: center
        align-items: center
    
    .video-title
        font-size: 2.5vh
        text-align: center

    .splide, ,.splide__track
        height: 100%
    
    .carousel-video
        aspect-ratio: 16 / 9
        width: 70%


@media screen and (max-height: 800px) and (min-aspect-ratio: 7/4) 
    .projects-container
        .title
            padding-top: 0 

        .carousel-container
            height: 70vh

@media screen and (orientation:portrait)
    .projects-container
        .title
            font-size: 5vh

    .carousel-video
        aspect-ratio: 16 / 9
        width: 90%