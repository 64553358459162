$red: #eb4a4a

.form
    scroll-behavior: smooth
    

.title
    display: block
    font-family: 'Poppins', sans-serif
    width: 100%
    text-align: center
    color: white

.form-container
    width: 85%
    height: 60vh
    transform: translateY(12vh)
    margin-left: auto
    margin-right: auto

    .title
        display: block
        font-family: 'Poppins', sans-serif
        width: 100%
        text-align: center
        font-size: 5vw
        color: white

.inputs-container
    padding-top: 5%
    width: 65%

.contacts-container 
    display: flex
    flex-direction: column
    width: 50%
    height: 100%
    padding-top: 5% 
    align-content: center
    margin: 0

    .light-text
        color: white
        font-size: 2vw
        font-weight: 300
        padding-left: 1vw 

.initials-container
    display: flex
    flex-direction: row
    height: 5vh
    align-items: center

.fasIcon
    height: 50%

.blocks-container
    display: flex
    flex-direction: row

#name
    line-height: 5vh
    padding-left: 2.5vw

.name-surname, .email
    display: flex
    flex-direction: row
    justify-content: space-around
    width: 100%
    margin-bottom: 5vh   

.email
    width: 50%

.message
    display: flex
    flex-direction: row
    justify-content: space-around
    width: 100%
    margin-bottom: 5vh
    
#first-name, #last-name, #email
    width: 22vw
    height: 5vh

#message
    width: 46vw
    height: 10vh
    font-size: 1vw

input, textarea
    background-color: #11111100 
    border: 0
    transition: border-bottom 1s
    border-bottom: 1px solid white
    color: white
    &:focus
        outline: none

label
    color: white
    &[for="message"]
        margin-bottom: 2vh
    font-size: 1vw

.group
    display: flex
    flex-direction: column

.submit-btn
    font-family: 'Poppins', sans-serif
    width: 10vw
    height: 2.5vw
    background-color: $red
    border: 0
    color: white
    font-size: 1vw
    font-weight: 300
    cursor: pointer
    border-radius: 0.1vw
    transform: translateX(36.7vw)
    transition: background-color 1s

@media (min-aspect-ratio: 7/4) 
    .contacts-container, .inputs-container
        padding-top: 2% 
    
    .form-container
        transform: translateY(8vh)


@media screen and (max-height: 800px) and (min-aspect-ratio: 7/4) 
    .form-container
            transform: translateY(3vh)

@media screen and (orientation:portrait) 
    .form-container
        transform: translateY(3vh) 
        width: 95%

        .title
            font-size: 5vh  

    .blocks-container
        display: flex
        flex-direction: column
        align-items: center
        
    .contacts-container
        >*
            text-align: center
            justify-content: center

    .inputs-container
        width: 75%
        > *
            flex-direction: column
            justify-content: center

    .group input, textarea
        width: 100%

    #first-name, #last-name, #email, #message
        width: 100%
        margin-bottom: 2vh
        height: 4vh
        font-size: 2vh

    #message
        margin-bottom: 0
        height: 5vh
        
    .email
        all: unset

    label
        font-size: 1.3vh

    .name-surname
        margin-bottom: 0

    .submit-btn
        transform: translateX(0)
        width: 25vw
        height: 8vw
        font-size: 3vw
        font-weight: 300
        border-radius: 0.2vw
        text-align: center
