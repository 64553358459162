$red: #eb4a4a

    
.scrollContainer
    position: relative
    width: 100vw
    height: 100vh
    scroll-behavior: auto
    overflow: auto
    scroll-snap-type: y mandatory
    overflow-x: hidden

.contact-me
    font-family: 'Poppins', sans-serif
    color: white
    border: 0
    background-color: #11111100
    font-weight: 100
    font-size: 1.2vw
    padding-right: 5vw
    cursor: pointer

.screen
    position: relative
    width: 100vw
    height: 100vh
    scroll-snap-align: start

header
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: flex-end
    align-items: center
    height: 7vh
    width: 100%
    background-color: black
    z-index: 100

footer
    position: fixed
    bottom: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    gap: 1vw
    height: 8vh
    width: 100%
    background-color: black

@media screen and (orientation: portrait)    
    footer
        gap: 5vw

