.skills
    background-color: red 

.skills-container
    display: flex
    flex-direction: row
    transform: translateY(15vh)

.skills-image
    position: absolute
    top: 20%
    z-index: -1
    object-fit: contain
    width:60%
    height: 70vh

.heading
    color: white
    font-size: 5vw
    font-weight: 300
    padding-left: 10vw
    transform: translateY(-5vh)

ul
    list-style-type: none

li
    color: white
    font-size: 1.5vw
    font-weight: 300

.apps
    margin-left: 10vw


@media screen and (orientation:portrait)
    .heading
        display: block
        font-size: 10vw
        width: 80%
        margin-left: auto
        margin-right: auto
        text-align: center
        font-weight: 400
        padding-top: 5vh
        transform: translateY(0)
        padding-left: 0

    .skills-container
        flex-direction: column
        align-content: flex-end
        width: 35%
        float: right
        transform: translateY(5vh)

    .apps, .skills-list
        margin-left: 0
        padding: 0
    
    li
        color: white
        font-size: 2vh
        font-weight: 400

    .skills-image
        scale: 2.5
        height: 70vh