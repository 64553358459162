$red: #eb4a4a
    
body
    background-color: black
    font-family: 'Poppins', sans-serif

.greetings-container
    display: flex
    flex-direction: column
    margin-left: 5vw
    transform: translateY(12vh)
    width: 35vw
    height: 70vh
    gap: 4vh

    .light-text
        color: white
        font-size: 2vw
        font-weight: 300

    .medium-text
        color: white
        font-size: 5vw
        line-height: 10vh

    .light-red-text
        color: #eb4a4a
        font-size: 2vw
        font-weight: 300

    .description-text
        color: white
        font-size: 1vw

.get-in-touch-btn
    font-family: 'Poppins', sans-serif
    width: 13vw
    height: 3.5vw
    background-color: $red
    border: 0
    color: white
    font-size: 1.5vw
    font-weight: 300
    cursor: pointer
    border-radius: 0.2vw 

.title-image
    position: absolute
    top: 12%
    left: 40%
    z-index: -1
    object-fit: contain
    width:60%
    height: 80vh

@media screen and (max-height: 800px) and (min-aspect-ratio: 7/4) 
    .greetings-container
            transform: translateY(3vh)


@media screen and (orientation: portrait)    
    .greetings-container
        transform: translateY(40vh)
        width: 75vw
        height: 30vh
        gap: 0
        margin-left: auto
        margin-right: auto

        .light-text
            font-size: 5.5vw

        .medium-text
            font-size: 8vw

        .light-red-text
            font-size: 4.5vw
            font-weight: 400

        .light-text, .medium-text, .light-red-text
            text-shadow: 2px 4px 10px #000000

        

    .get-in-touch-btn
        width: 25vw
        height: 8vw
        font-size: 3vw
        font-weight: 300
        margin-right: auto
        margin-left: auto
        transform: translate(38vw, 40vh)


    .title-image
        all: unset
        position: absolute
        top: 10%
        left: -30vw
        z-index: -1
        object-fit: fill
        width: auto
        height: 80vh
        overflow: hidden
        