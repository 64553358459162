.games-container 
    width: 80%
    height: 92vh
    transform: translateY(12vh)
    margin-left: auto
    margin-right: auto
    position: relative

    .title
        display: block
        font-family: 'Poppins', sans-serif
        width: 100%
        text-align: center
        font-size: 5vw
        color: white
        margin-bottom: 5vh 

.inner-container
    display: flex
    justify-content: space-around
    width: 100%
    max-height: 50vh    

.game 
    display: flex
    flex-direction: column
    justify-content: flex-start
    width: 25%
    padding: 1% 1% 1% 1%
    border: 2px solid #ffffff51

    div
        max-height: 30%

    .medium-text
        color: white
        font-size: 2vw
        letter-spacing: 0.1vw
        padding: 0
    
    .light-text
        color: white
        font-size: 1.5vw
        font-weight: 300
        padding: 0

.poster
    display: block
    max-width: 100%
    max-height: 45%

.game-link
    color: white
    font-family: 'Poppins', sans-serif
    display: inline
    width: 3vw

@media (min-aspect-ratio: 7/4) 
    .game
        display: grid
        grid-template-columns: 10vw 7.5vw 7.5vw 20vw 5vw 
        width: 57vw
        gap: 3vh

        > *
            margin-top: auto
            margin-bottom: auto
            text-align: left

        .medium-text, .light-text
            display: flex
            flex-direction: column
            justify-content: flex-start

        .light-text
            font-size: 1vw

        .medium-text
            font-size: 2vw
    
    .poster
        max-height: 100%

    .inner-container
        flex-direction: column
        align-items: center
        justify-content: space-around
        gap: 4vh        


@media screen and (max-height: 800px) and (min-aspect-ratio: 7/4) 
    .games-container
            transform: translateY(3vh)

@media screen and (orientation:portrait)    
    .games-container .title
        font-size: 5vh

    .inner-container
        display: block
        margin-top: 15%
    .li        
        width: auto
        height: auto

    .game
        width: 60%
        padding: 1% 1% 1% 1%
        border: 2px solid #ffffff51
        margin: 0
        transform: translateX(14.5vw)

        div
            max-height: 30%

        .poster
            display: block
            max-width: 100%
            max-height: 25%

        .medium-text
            color: white
            font-size: 4vw
        
        .light-text
            color: white
            font-size: 2.5vw
            font-weight: 300
            padding: 0